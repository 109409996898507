import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./SucessStories.module.scss";
import Logo from "../../assets/CTF-Nuevo-large-black.png";
import Agro from "../../assets/Agro.jpg"
import Salud from "../../assets/Salud.jpg"
import Mec from "../../assets/Mec.jpg"

//import LogoBlack from "../../assets/CTF-Nuevo-large-black.png";

const SucessStories = () => {
    return (
        <div className={styles.container} id="sucessstories">
            <div className={styles.header}>
                <h1>Casos de éxito</h1>
                <p>Contamos con amplia experiencia en el desarrollo de soluciones para los
                    sectores de telecomunicaciones, agroindustria y comercio electrónico.
                </p>
            </div>
            <div className={styles.columns}>
                <div className={styles.column}>
                    <img src={Agro} alt="Desarrollos a medida" className={styles.icon} />
                    <h3>Wiagro</h3>
                    <p>En el sector agrícola, para la empresa Wiagro, creamos una aplicación
                        web y mobile que permite a los usuarios monitorear el estado de los
                        silobolsas desde cualquier lugar del mundo. Este avance tecnológico
                        optimiza la cadena de suministro agrícola y brinda seguridad y
                        control en tiempo real.
                    </p>
                </div>
                <div className={styles.column}>
                    <img src={Salud} alt="Desarrollos a medida" className={styles.icon} />
                    <h3>Sanikum</h3>
                    <p>En Sanikum, una compañía líder en medicina prehospitalaria,
                        creamos un sistema de telemedicina avanzado. El sistema
                        monitorea datos como la temperatura corporal, presión, y
                        niveles de oxígeno, conectando a los pacientes con un asistente
                        virtual (Manejado por IA) que facilita el proceso de derivacion
                        al médico adecuado. ¡Un paso adelante en la atención médica inteligente!
                    </p>
                </div>
                <div className={styles.column}>
                    <img src={Mec} alt="Desarrollos a medida" className={styles.icon} />
                    <h3>Motoasesor</h3>
                    <p>Para MotoAsesor, un taller de motos de alta gama,
                        desarrollamos una web moderna con marketplace incluido.
                        Ahora, los usuarios pueden acceder a servicios y productos
                        especializados en línea, con facilidad y rapidez, haciendo
                        de MotoAsesor la elección perfecta para los apasionados de
                        las motos.
                    </p>
                </div>
            </div>
        </div>
    );
};


SucessStories.propTypes = {};

export default SucessStories;
