import "./App.scss";
import Technologies from "./components/Technologies/Technologies";
import Contact from "./components/Contact/Contact";
import Header from "./components/Header/Header";
import Hero from "./components/Hero/Hero";
import Services from "./components/Services/Services";
import Us from "./components/Us/Us";
import Footer from "./components/Footer/Footer";
import SucessStories from "./components/SuccessStories/SucessStories";

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Services />
      <Us />
      <SucessStories />
      <Technologies />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
