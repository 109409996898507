import React from "react";
import PropTypes from "prop-types";
import styles from "./Hero.module.scss";
import HeroGraphic from "../../assets/hero-graphic.svg";
import { handleScrollToContact } from "../../utils";

import heroBg from "../../assets/hero.jpg";

const Hero = (props) => {
  return (
    <div className={styles.hero} style={{ backgroundImage: `url(${heroBg})` }}>
      <div className={styles.heroMask}></div>
      <div className={`wrapper ${styles.wrapper}`}>
        <div className={styles.container}>
          <div>
            <p className={styles.heroTitle}>
              Hacemos realidad tus ideas con soluciones tecnológicas innovadoras.
            </p>
            <p className={styles.heroUpperTitle}>
              Desarrollo de software WEB & Mobile<br />
              Asesorias<br />
              Integracion de talento
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

Hero.propTypes = {};

export default Hero;
