import React, { useState } from "react";
import styles from "./Footer.module.scss";
import Logo from "../../assets/logo-white.svg";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import Swal from "sweetalert2";
import { objectToFormData } from "../Contact/Contact";

const Footer = (props) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    if (isLoading || email.trim() === "") return;

    setIsLoading(true);

    const body = {
      email,
    };

    try {
      const response = await axios({
        method: "POST",
        url: `https://ctflatam.com/api/send-mail-newsletter.php`,
        data: objectToFormData(body),
      });

      if (response.data.status !== "ok") throw new Error();
      await new Promise((resolve) => setTimeout(resolve, 2000));

      await Swal.fire({
        title: "Suscrito correctamente!",
        text: "Se ha suscrito correctamente al newsletter!",
        icon: "success",
        confirmButtonColor: "#021840",
      });
    } catch (err) {
      setIsLoading(false);
      await Swal.fire({
        title: "Error al suscribirse",
        text: "Por favor intente nuevamente",
        icon: "error",
        confirmButtonColor: "#021840",
      });
    }
  };

  return (
    <div className={`${styles.footer}`}>
      <div className={`wrapper ${styles.card}`}>
        <button
          className={styles.logo}
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <img src={Logo} alt="CTF Latam" />
          <p className={styles.copyright}>
            Copyright © {new Date().getFullYear()} CTF Latam
          </p>
          <p className={styles.copyright}>All rights reserved</p>
        </button>
        <div className={styles.info}>
          <p className={styles.bold}>Company</p>
          <p className={styles.buttons}>Nosotros</p>
          <p className={styles.buttons}>Servicios</p>
          <p className={styles.buttons}>Tecnologías</p>
          <p className={styles.buttons}>Casos de éxito</p>
          <p className={styles.buttons}>Contacto</p>
        </div>
        <div className={styles.info}>
          <p className={styles.bold}>Support</p>
          <p className={styles.info2}>info@ctflatam.com</p>
          <p className={styles.info2}>Buenos Aires, Argentina</p>
          <p className={styles.info2}>+54 911 3219-8883</p>
        </div>
        <div className={styles.info}>
          <p className={styles.bold}>Mantenete conectado</p>
          <div className={styles.emailContainer}>
            <input
              type="email"
              placeholder="Tu email..."
              value={email}
              onChange={handleEmailChange}
              className={styles.emailInput}
              disabled={isLoading}
            />
            <button
              onClick={handleSubmit}
              className={styles.iconButton}
              disabled={isLoading}
            >
              <SendIcon style={{ color: "#fff" }} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {};

export default Footer;
