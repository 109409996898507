import React from "react";
import PropTypes from "prop-types";
import styles from "./Services.module.scss";
import Service from "./Service";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';

import SoftwareFactoryGraphic from "../../assets/services/software-factory.svg";
import ConsultingGraphic from "../../assets/services/consulting.svg";
import StaffingGraphic from "../../assets/services/staffing.svg";
import manos from "../../assets/services/A-medida.jpg"
import seleccion from "../../assets/services/Integracion-talento.jpg"
import web from "../../assets/services/web-work.jpg"

const Services = (props) => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <div className={`section`} id="services">
      <div className={`wrapper`}>
        <h2 className={`sectionTitle`}>Nuestros servicios</h2>
        <p className={styles.text}>Tomamos cada proyecto como si fuera único, asegurando que nuestras soluciones añadan valor y contribuyan significantivamente al avance técnologico.</p>
        <div className={styles.servicesContainer}>
          {/* Columna 1 */}
          <div className={styles.serviceColumn} data-aos="fade-right" data-aos-duration="1000">
            <img src={web} alt="Desarrollos a medida" className={styles.serviceImage} />
            <div className={styles.serviceBox}>
              <h3>Sitios Web Impactantes</h3>
              <p>
                Creamos sitios personalizados que no solo impresionan, sino que también impulsan tu marca
              </p>
            </div>
          </div>

          {/* Columna 2 */}
          <div className={styles.serviceColumn} data-aos="fade-up" data-aos-duration="1500">
            <img src={manos} alt="Staff Augmentation" className={styles.serviceImage} />
            <div className={styles.serviceBox}>
              <h3>Desarrollos a medida</h3>
              <p>
                Diseñamos sistemas de gestión y aplicaciones que simplifican procesos, ahorran tiempo y maximizan resultados.
              </p>
            </div>
          </div>

          {/* Columna 3 */}
          <div className={styles.serviceColumn} data-aos="fade-left" data-aos-duration="1500">
            <img src={seleccion} alt="Consultoría" className={styles.serviceImage} />
            <div className={styles.serviceBox}>
              <h3>Integracion de talento</h3>
              <p>
                Proporcionamos el talento necesario para que logres tus objetivos sin complicaciones.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Services.propTypes = {};

export default Services;
