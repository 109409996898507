import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./Technologies.module.scss"; // Cambié el nombre del archivo a Technologies

import VsDode from "../../assets/clients/vscode.png";
import Node from "../../assets/clients/node.png";
import Js from "../../assets/clients/Js.png";
import Angular from "../../assets/clients/Angular.png";
import Vue from "../../assets/clients/Vue.png";
import Sql from "../../assets/clients/sql.png";
import Code from "../../assets/Imagen-Js-Code.jpg";

import Slider from "react-slick";

const CLIENTS = [
  {
    title: ".Net",
    image: VsDode,
  },
  {
    title: "NodeJs",
    image: Node,
  },
  {
    title: "JavaScript",
    image: Js,
  },
  {
    title: "Angular",
    image: Angular,
  },
  {
    title: "Vue",
    image: Vue,
  },
  {
    title: "Sql Server",
    image: Sql,
  },
];

const Technologies = (props) => {
  const [current, setCurrent] = useState(0);

  let sliderRef = useRef(null);

  const settings = {
    arrows: false,
    swipeToSlide: false,
    draggable: false,
    infinite: true,
    dots: false,
    slidesToShow: 5,
    autoplay: true,
    autoplaySpeed: 1800,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className={`${styles.clients} section dark`} id="technologies">
      <div className="wrapper">
        <h2 className={`sectionTitle`}>Tecnologías que potencian tus proyectos</h2>

        <div className={styles.mainContainer}>
          <div className={styles.imageContainer}>
            <img src={Code} alt="Tecnologías" className={styles.image} />
          </div>

          <div className={styles.textContainer}>
            <div className={styles.currentClient}>
              <p className={styles.body}>
                En nuestro equipo utilizamos tecnologías modernas y versátiles
                para garantizar soluciones sólidas y eficientes. Desarrollamos
                interfaces dinámicas y escalables que mejoran la experiencia del
                usuario, así como también gestionamos datos de manera eficiente,
                asegurando integridad y escalabilidad en cada proyecto.
              </p>
            </div>

            <div className={styles.sliderContainer}>
              <Slider {...settings} ref={(slider) => (sliderRef = slider)}>
                {CLIENTS.map((client, index) => (
                  <div className={styles.clientSlide} key={index}>
                    <img src={client.image} alt={client.title} />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Technologies.propTypes = {};

export default Technologies;
